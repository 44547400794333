import React from 'react';
import Popover from 'react-bootstrap/Popover';
import * as styles from './popover-satisfacao-garantida.module.scss';

const PopoverSatisfacaoGarantida = (
  <Popover id="popover-satisfacao-garantida">
    <Popover.Content>
      <div className={styles.container}>
        <p className="m-0">
          Se você não curtir o Simples Dental, a gente devolve o seu dinheiro! Nosso objetivo é
          fazer você feliz e satisfeito com a nossa solução, sem dor de cabeça.
        </p>
        <a
          href="https://ajuda.simplesdental.com/pt-BR/articles/6387374-como-realizar-o-reembolso-da-mensalidade?_ga=2.210983957.167933768.1681749414-1358091769.1670940784"
          target="_bank"
          className={styles.linkPopover}
        >
          saiba mais
        </a>
      </div>
    </Popover.Content>
  </Popover>
);

export default PopoverSatisfacaoGarantida;
