import React from 'react';
import { Button } from '@/components';
import Container from 'react-bootstrap/Container';
import * as styles from './migrar.module.scss';
import connector from '@/assets/images/migracao-connector.svg';
import fromThis from '@/assets/images/tela-do-prontuario-antes-da-migracao-para-simples-dental.png';
import toThis from '@/assets/images/tela-do-prontuario-depois-da-migracao-para-simples-dental.png';
import fromThisToThis from '@/assets/images/tela-do-prontuario-antes-e-depois-da-migracao-para-simples-dental.png';
import { buttonEvents, migracaoDadosButtonNames } from '@/constants/analytics';
import { trackButtonClick } from '@/services/analytics';

import emojiNeutralFace from '@/assets/images/emoji-neutral-face.png';
import emojiPassionate from '@/assets/images/emoji-passionate.png';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

const Migrar = () => {
  const handleFalarEspecialista = () => {
    navigate(routes.migracao.cadastroSolicitarContato);
    trackButtonClick({
      event: buttonEvents.migracaoDados,
      buttonName: migracaoDadosButtonNames.falarEspecialistaMigracao,
    });
  };

  return (
    <div className={styles.container}>
      <Container className={styles.innerContainer}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <p className={styles.subtitle}>Migração facilitada</p>
            <h2 className={styles.title}>Migre seus dados em poucos passos</h2>
          </div>
          <div className={styles.buttonWrapper}>
            <div>
              <p className={styles.subtitle}>
                Conte com os nossos especialistas para migrar seus dados com segurança e agilidade.
              </p>
            </div>
            <div>
              <div className={styles.buttonDesktop}>
                <Button
                  variant="green"
                  text="Falar com especialista"
                  onClick={handleFalarEspecialista}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.image}>
            <div className={styles.titleImage}>
              <img src={emojiNeutralFace} alt="emoji rosto neutro" /> evolua disso...
            </div>
            <img src={fromThis} title="Evolua disso..." alt="Evolua disso..." height={575} />
          </div>
          <img className={styles.connector} src={connector} alt="Conector" />
          <div className={styles.image}>
            <div className={styles.titleImage}>
              <img src={emojiPassionate} alt="emoji apaixonado" /> para isso!
            </div>
            <img src={toThis} title="... Para isso" alt="... Para isso" height={584} />
          </div>
        </div>
        <div>
          <img
            className={styles.imageResponsive}
            src={fromThisToThis}
            title="Evolua disso... Para isso"
            alt="Evolua disso para isso"
            width={360}
            height={253}
          />
        </div>

        <div className={styles.buttonResponsivo}>
          <Button variant="green" text="Falar com especialista" onClick={handleFalarEspecialista} />
        </div>
      </Container>
    </div>
  );
};

export default Migrar;
