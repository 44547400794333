import CheckCircle from '@/components/Icons/CheckCircle';
import UncheckCircle from '@/components/Icons/UncheckCircle';
import React from 'react';
import { Container, Table } from 'react-bootstrap';
import * as styles from './comparative.module.scss';
import { comparativeTable } from './constants';
import Info from '@/assets/svg/info.svg';
import { PopoversNames } from '../Popovers';
import PopoverAssinaturaEletronica from '../Popovers/PopoverAssinaturaEletronica';
import PopoverSatisfacaoGarantida from '../Popovers/PopoverSatisfacaoGarantida';
import PopoverUsabilidade from '../Popovers/PopoverUsabilidade';
import Trigger from 'react-bootstrap/OverlayTrigger';
import Card from './Card';
import clsx from 'clsx';

const Comparative = () => {
  const popover = {
    [PopoversNames.assinaturaEletronica]: PopoverAssinaturaEletronica,
    [PopoversNames.satisfacaoGarantida]: PopoverSatisfacaoGarantida,
    [PopoversNames.usabilidade]: PopoverUsabilidade,
  };

  return (
    <Container>
      <div className={styles.container}>
        <p className={styles.headline}>Escolha certa</p>
        <h2 className={styles.title}>
          Descubra porque <span>milhares de clínicas</span> já mudaram para o Simples Dental
        </h2>
        <div className={styles.image}></div>
        <Table striped className={styles.tableWrapper}>
          <thead>
            <tr>
              <th></th>
              <th>Simples Dental</th>
              <th>Sistema B</th>
              <th>Sistema C</th>
            </tr>
          </thead>
          <tbody>
            {comparativeTable.map(({ caracteristica, simplesDental, sistemaB, sistemaC }) => (
              <tr key={caracteristica.text}>
                <td>
                  <div className={styles.desc}>
                    {caracteristica.text}
                    {caracteristica?.logo && (
                      <span>
                        <img src={caracteristica.logo} alt="Logo loja" />
                      </span>
                    )}
                    {caracteristica?.popoverName && (
                      <div>
                        <Trigger
                          trigger="click"
                          placement="auto"
                          overlay={popover[caracteristica.popoverName]}
                          rootClose
                        >
                          <Info className={styles.infoIconSmall} />
                        </Trigger>
                      </div>
                    )}
                  </div>
                </td>
                <td className={styles.checked}>
                  {!simplesDental?.text ? (
                    <CheckCircle color="#499F3A" />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: simplesDental.text }}></div>
                  )}
                  {simplesDental?.info && <span>{simplesDental?.info}</span>}
                </td>

                <td className={clsx({ [styles.checked]: sistemaB?.hasFeature })}>
                  {sistemaB?.hasFeature && <CheckCircle color="#499F3A" />}

                  {!sistemaB?.text && !sistemaB?.hasFeature && <UncheckCircle color="#FF5E5E" />}

                  {sistemaB?.text && (
                    <div dangerouslySetInnerHTML={{ __html: sistemaB?.text }}></div>
                  )}

                  {sistemaB?.text && <span>{sistemaB.info}</span>}
                </td>

                <td>
                  {!sistemaC?.text ? (
                    <UncheckCircle color="#FF5E5E" />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: sistemaC.text }}></div>
                  )}
                  {sistemaC?.text && <span>{sistemaC.info}</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Card />
    </Container>
  );
};

export default Comparative;
