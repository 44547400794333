import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverUsabilidade = (
  <Popover id="popover-usabilidade">
    <Popover.Content>
      <p className="m-0">
        Com base em Benchmark de Testes de Usabilidade e carga cognitiva por áreas clicáveis ao
        criar a primeira consulta.
      </p>
    </Popover.Content>
  </Popover>
);

export default PopoverUsabilidade;
