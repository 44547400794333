// extracted by mini-css-extract-plugin
export var button = "hero-module--button--c0e52";
export var card = "hero-module--card--9e7cd";
export var container = "hero-module--container--8f00d";
export var desc = "hero-module--desc--09917";
export var descFeature = "hero-module--desc-feature--229a1";
export var descResponsivo = "hero-module--desc-responsivo--f4e59";
export var feat = "hero-module--feat--d8952";
export var footerImage = "hero-module--footer-image--eb324";
export var footerText = "hero-module--footer-text--786bc";
export var footerWrapper = "hero-module--footer-wrapper--d7111";
export var heroActions = "hero-module--hero-actions--917e0";
export var heroButtons = "hero-module--hero-buttons--f610e";
export var highlight = "hero-module--highlight--74659";
export var imgDesktop = "hero-module--img-desktop--31f9c";
export var imgResponsivo = "hero-module--img-responsivo--8b2be";
export var imgWrapper = "hero-module--img-wrapper--3569d";
export var infos = "hero-module--infos--b2c3a";
export var innerContainer = "hero-module--inner-container--d6c5e";
export var logo = "hero-module--logo--cf89b";
export var title = "hero-module--title--f265f";
export var traco = "hero-module--traco--d4133";
export var wrapper = "hero-module--wrapper--fd54a";