import React from 'react';
import Container from 'react-bootstrap/Container';
import * as styles from './product.module.scss';
import solucaoIntegrada from '@/assets/images/solucao-integrada.png';
import check from '@/assets/images/check.svg';

const Product = () => {
  return (
    <div className={styles.container}>
      <Container className={styles.innerContainer}>
        <div className={styles.funcionalidadeImg}>
          <img src={solucaoIntegrada} alt="Solução integrada" />
        </div>

        <div className={styles.funcionalidadeText}>
          <div className={styles.head}>Tudo isso e muito mais!</div>
          <div className={styles.logoWrapper}>
            <span className={styles.desc}>
              O software criado para simplificar a rotina das suas clínicas
            </span>
          </div>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Migração simplificada" />
            <span className={styles.title}>Migração simplificada*</span>
          </div>
          <p className={styles.desc}>
            Migre histórico de evoluções, financeiro do paciente e dados cadastrais gratuitamente.
          </p>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Software completo para sua clínica" />
            <span className={styles.title}>Software completo para sua clínica</span>
          </div>
          <p className={styles.desc}>
            Use o software completo, com todos os recursos para ajudar sua clínica crescer.
          </p>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Funcionalidades exclusivas" />
            <span className={styles.title}>Funcionalidades exclusivas</span>
          </div>
          <p className={styles.desc}>Funcionalidades exclusivas como as de Ortodontia e HOF.</p>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Indiscutivelmente o melhor" />
            <span className={styles.title}>Indiscutivelmente o melhor</span>
          </div>
          <p className={styles.desc}>
            Empresa do grupo da Dental Cremer e Dental Speed, maiores dentais do Brasil.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Product;
