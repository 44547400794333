import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverAssinaturaEletronica = (
  <Popover id="popover-assinatura-eletronica">
    <Popover.Content>
      <p className="m-0">
        Em conformidade com a lei 14.063 de 2020, nossa assinatura eletrônica
        <strong> utiliza a plataforma com o mais alto nível de segurança</strong>, pronta para
        coletar assinaturas rapidamente e sem a necessidade de adquirir certificados digitais.
      </p>
      <br />
      <p className="m-0">
        São mais de 11 milhões de documentos assinados em 8 países e reconhecimento de grandes
        empresas do mercado.
      </p>
    </Popover.Content>
  </Popover>
);

export default PopoverAssinaturaEletronica;
