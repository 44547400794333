// extracted by mini-css-extract-plugin
export var buttonDesktop = "migrar-module--buttonDesktop--456f4";
export var buttonResponsivo = "migrar-module--buttonResponsivo--a1e10";
export var buttonWrapper = "migrar-module--button-wrapper--e2e1d";
export var connector = "migrar-module--connector--d9d73";
export var container = "migrar-module--container--b4f00";
export var content = "migrar-module--content--17a2a";
export var header = "migrar-module--header--89cc6";
export var headerContent = "migrar-module--header-content--f0b58";
export var image = "migrar-module--image--082d3";
export var imageResponsive = "migrar-module--image-responsive--9eff3";
export var innerContainer = "migrar-module--inner-container--86b9b";
export var subtitle = "migrar-module--subtitle--21805";
export var title = "migrar-module--title--978b9";
export var titleImage = "migrar-module--title-image--1c7e5";