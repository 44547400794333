import React from 'react';
import { Seo } from '@/components';
import { Container } from 'react-bootstrap';
import Root from '@/layouts/Root/Root';
import Hero from '@/layouts/MigracaoDados/Hero';
import Feedbacks from '@/layouts/MigracaoDados/Feedbacks/Feedbacks';
import Product from '@/layouts/MigracaoDados/Product';
import Numbers from '@/layouts/MigracaoDados/Numbers';
import Migrar from '@/layouts/MigracaoDados/Migrar';
import Details from '@/layouts/MigracaoDados/Details';
import Footer from '@/layouts/MigracaoDados/Footer';
import Cards from '@/layouts/MigracaoDados/Cards/index';
import Comparative from '@/layouts/MigracaoDados/Comparative/Comparative';
import { MigracaoCards } from '@/layouts/MigracaoDados/Cards/constants';
import { MigracaoDetails } from '@/constants/pageDetails';
import { categories, migracaoDadosActions } from '@/constants/analytics';
import { routes } from '@/constants/routes';

const MigracaoDados = () => {
  return (
    <>
      <Seo
        title="Mudar para o Simples Dental faz toda a diferença "
        description="Migre seus dados de outro Software Odontológico para a Simples Dental. Descubra como transferir suas informações odontológicas com segurança e eficiência para nosso sistema."
      />
      <Root variant="light" hero={<Hero />}>
        <Cards
          title="Dentista, você se identifica com alguns desses desafios?"
          subtitle="Simplificamos a sua rotina"
          cards={MigracaoCards}
          link={routes.migracao.cadastro}
          gaMetadata={{
            category: categories.migracaoDados,
            action: migracaoDadosActions.cardsSimplificamosTestar,
          }}
        />
        <Container>
          <hr />
        </Container>
        <Feedbacks />
        <Numbers />
        <Product />
        <Comparative />
        <Migrar />
        <Details
          title="Você com mais tempo para ser dentista"
          subtitle="Simplifique sua rotina de trabalho"
          items={MigracaoDetails}
        />
        <Footer />
      </Root>
    </>
  );
};

export default MigracaoDados;
