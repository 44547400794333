import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from '@/components/Button';
import * as styles from './hero.module.scss';

import { trackButtonClick } from '@/services/analytics';
import { buttonEvents, migracaoDadosButtonNames } from '@/constants/analytics';

import logo from '@/assets/images/logo.svg';
import feat from '@/assets/images/feat.svg';
import migracaoDados from '@/assets/images/tela-computador-na-agenda-simples-dental.png';
import migracaoDadosResponsivo from '@/assets/images/computador-na-agenda-simples-dental.png';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

const Hero = () => {
  const handleTestarGratis = () => {
    navigate(routes.migracao.cadastro);
    trackButtonClick({
      event: buttonEvents.migracaoDados,
      buttonName: migracaoDadosButtonNames.testarGratisHero,
    });
  };

  const handleFalarEspecialista = () => {
    navigate(routes.migracao.cadastroSolicitarContato);
    trackButtonClick({
      event: buttonEvents.migracaoDados,
      buttonName: migracaoDadosButtonNames.falarEspecialistaHero,
    });
  };

  return (
    <div className={styles.container}>
      <Container className={styles.innerContainer}>
        <div>
          <div className={styles.wrapper}>
            <div className={styles.logo}>
              <img src={logo} alt="Simples Dental" />
            </div>

            <div>
              <p className={styles.highlight}>Indiscutivelmente o melhor</p>
              <h1>
                <span className={styles.title}>“Fantástico. O melhor</span>
                <span className={styles.title}>
                  software de <span className={styles.traco}>odontologia</span>
                </span>
                <span className={styles.title}>do mercado. Disparado!”</span>
              </h1>

              <p className={styles.desc}>
                Com a <strong>maior nota</strong> de avaliação,
                <strong>melhor índice</strong> de satisfação do mercado e o atendimento{' '}
                <strong>mais ágil</strong>. Tudo isso sem custos adicionais por usuário ou
                armazenamento de imagens
              </p>
            </div>

            <div className={styles.heroActions}>
              <div className={styles.heroButtons}>
                <Button text="Quero testar" variant="orange" onClick={handleTestarGratis} />
                <Button
                  text="Falar com especialista"
                  variant="green"
                  onClick={handleFalarEspecialista}
                />
              </div>
            </div>

            <div className={styles.feat}>
              <img
                className={styles.footerImage}
                src={feat}
                alt="O melhor software de odontologia do mercado."
              />
              <p className={styles.descFeature}>
                <strong>Migre seus dados de outros softwares para o Simples Dental!</strong>
              </p>
            </div>
          </div>

          <div className={styles.imgDesktop}>
            <img
              src={migracaoDados}
              alt="Agenda eletrônica Simples Dental"
              title="Fantástico. O melhor software de odontologia do mercado"
              width={700}
            />
          </div>
        </div>
      </Container>
      <div className={styles.footerWrapper}>
        <div className={styles.imgWrapper}>
          <p className={styles.descResponsivo}>
            <strong> Migre seus dados de outros softwares para o Simples Dental</strong>
          </p>
          <img
            src={migracaoDadosResponsivo}
            alt="Agenda eletrônica Simples Dental"
            title="Fantástico. O melhor software de odontologia do mercado"
            className={styles.imgResponsivo}
            width={328}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
