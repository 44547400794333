import { PopoversNames } from '../Popovers';
import appleLogo from '@/assets/icons/apple-logo.svg';
import googlePlayLogo from '@/assets/icons/googleplay-logo.svg';

export interface TableRowProps {
  popoverName?: string;
  logo?: string;
  text?: string;
  info?: string;
  hasFeature?: boolean;
}
export interface ComparativeTableRow {
  caracteristica: TableRowProps;
  simplesDental?: TableRowProps;
  sistemaB?: TableRowProps;
  sistemaC?: TableRowProps;
}

export const comparativeTable: ComparativeTableRow[] = [
  {
    caracteristica: { text: 'Melhor nota no Reclame Aqui' },
    simplesDental: { text: '8,7 / 10' },
    sistemaB: { text: 'Sem índice' },
    sistemaC: { text: 'Sem índice' },
  },
  {
    caracteristica: { text: 'Avaliação média na', logo: appleLogo },
    simplesDental: { text: '4,9 / 5', info: 'com base em 734 avaliações' },
    sistemaB: { text: '<strong>2,7 / 5</strong>', info: 'com base em 83 avaliações' },
    sistemaC: { text: '<strong>2,7 / 5</strong>', info: 'com base em 30 avaliações' },
  },
  {
    caracteristica: { text: 'Avaliação média na', logo: googlePlayLogo },
    simplesDental: { text: '4,7 / 5', info: 'com base em 627 avaliações' },
    sistemaB: { text: '<strong>3,8 / 5</strong>', info: 'com base em 28 avaliações' },
    sistemaC: { text: '<strong>2,2 / 5</strong>', info: 'com base em 22 avaliações' },
  },
  {
    caracteristica: {
      text: 'Satisfação garantida',
      popoverName: PopoversNames.satisfacaoGarantida,
    },
  },
  {
    caracteristica: {
      text: 'Assinatura eletrônica simples e segura',
      popoverName: PopoversNames.assinaturaEletronica,
    },
  },
  {
    caracteristica: {
      text: 'Software mais eficiente em usabilidade',
      popoverName: PopoversNames.usabilidade,
    },
    simplesDental: { text: '<a>85%</a> mais eficiente', info: 'que os demais softwares' },
    sistemaB: { text: '<strong>70% de eficiência</strong>' },
    sistemaC: { text: '<strong>41% de eficiência</strong>' },
  },
  {
    caracteristica: { text: 'Crie um orçamento com contrato integrado' },
  },
  {
    caracteristica: { text: 'Importação de contatos via aplicativo' },
  },
  {
    caracteristica: { text: 'Armazene imagens ilimitadas' },
  },
  {
    caracteristica: { text: 'Cadastro de novos usuários ilimitados' },
  },
  {
    caracteristica: { text: 'Facilidade para buscar a Central de Ajuda' },
    sistemaB: { hasFeature: true },
  },
  {
    caracteristica: { text: 'Materiais de Apoio e Educação' },
    simplesDental: { text: 'Mais de 360 conteúdos educacionais já criados' },
    sistemaB: { text: 'Falta material de apoio' },
    sistemaC: { text: 'Sem material de apoio' },
  },
  {
    caracteristica: { text: 'Prazo para atendimento de suporte' },
    simplesDental: { text: '<a>75%</a> mais eficiente', info: 'comparado com outros softwares' },
    sistemaB: { text: '1 dia útil' },
    sistemaC: { text: '2 dias úteis' },
  },
];
