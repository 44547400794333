// extracted by mini-css-extract-plugin
export var container = "footer-module--container--1940f";
export var copyrightText = "footer-module--copyright-text--39652";
export var divider = "footer-module--divider--0ebcf";
export var dividerWeb = "footer-module--divider-web--19ecf";
export var footerWrapper = "footer-module--footer-wrapper--ad661";
export var innerContainer = "footer-module--inner-container--fe0f2";
export var light = "footer-module--light--3964b";
export var link = "footer-module--link--3f9dd";
export var linkWrapper = "footer-module--link-wrapper--b088e";
export var logo = "footer-module--logo--d712f";
export var main = "footer-module--main--c8349";
export var note = "footer-module--note--47e39";
export var subtitle = "footer-module--subtitle--e897c";
export var text = "footer-module--text--bda33";
export var title = "footer-module--title--e1ae1";